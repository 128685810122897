// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Define base and glowing styles */
.glow {
    transition: background-color 1s ease;
  }
  
.glow-update {
    background-color: rgb(255, 255, 255, 0.3); /* Semi-transparent yellow for glowing effect */
}

.table-container {
  display: block;
  position: relative;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  display: block;
  position: sticky;
  top: 0;
}

.table-container tbody {
  display: block;
  max-height: calc(100vh - 210px); /* Adjust the height as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.table-container th, .table-container td {
  min-width: 120px; /* Adjust the minimum width as needed */
  padding: 8px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,oCAAoC;EACtC;;AAEF;IACI,yCAAyC,EAAE,+CAA+C;AAC9F;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,MAAM;AACR;;AAEA;EACE,cAAc;EACd,+BAA+B,EAAE,gCAAgC;EACjE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB,EAAE,uCAAuC;EACzD,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["/* Define base and glowing styles */\n.glow {\n    transition: background-color 1s ease;\n  }\n  \n.glow-update {\n    background-color: rgb(255, 255, 255, 0.3); /* Semi-transparent yellow for glowing effect */\n}\n\n.table-container {\n  display: block;\n  position: relative;\n}\n\n.table-container table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table-container thead {\n  display: block;\n  position: sticky;\n  top: 0;\n}\n\n.table-container tbody {\n  display: block;\n  max-height: calc(100vh - 210px); /* Adjust the height as needed */\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.table-container th, .table-container td {\n  min-width: 120px; /* Adjust the minimum width as needed */\n  padding: 8px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
